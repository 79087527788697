import axios from "axios";
export default {
  data() {
    return {
      _downLoading: false
    };
  },
  methods: {
    downloadXlsx(durl, params) {
      return new Promise((resolve, reject) => {
        if (this._downLoading) return;
        this._downLoading = true;
        const url = process.env.VUE_APP_BASE_API + durl;
        axios
          .get(url, {
            params,
            headers: {
              Authorization: "Bearer " + this.$store.getters.token,
              "Content-Type": "application/vnd.ms-excel;charset=GBK"
            },
            responseType: "blob"
          })
          .then(res => {
            this._downLoading = false;
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel;charset=GBK"
            });
            const a = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            a.href = href;
            const _fileName = res.headers["content-disposition"]
              .split(";")[2]
              .split("=")[1]
              .split(`''`)[1];
            a.download = decodeURIComponent(_fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(href);
            this._downLoading = false;
            resolve(true);
          })
          .catch(err => {
            console.log(err);
            this._downLoading = false;
            reject(err);
          });
      });
    }
  }
};
