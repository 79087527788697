<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
    />
    <div class="file-container">
      <el-image :src="getImage"></el-image>
      <div v-if="fileName" class="file-name">{{ fileName }}</div>
    </div>
    <div
      class="drop"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      <div class="oprate">
        <el-button
          :loading="loading"
          size="large"
          plain
          type="primary"
          @click="handleUpload"
          >{{ fileName ? "重新选择" : "选择文件" }}</el-button
        >
      </div>
      <div v-if="!fileName" class="upload-tip hover-danger">
        只能上传excel文件，且必须使用模板格式
      </div>
    </div>
  </div>
</template>

<script>
// import XLSX from "xlsx";
export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function,// eslint-disable-line
    modelUrl: String
  },
  data() {
    return {
      loading: false,
      fileName: "",
      excelData: {
        header: null,
        results: null
      }
    };
  },
  computed: {
    getImage() {
      if (this.fileName) {
        return require(`@/assets/images/filetype/excel.png`);
      } else {
        return require(`@/assets/images/filetype/folder.png`);
      }
    }
  },
  created() {
    console.log(this.excelData);
  },
  methods: {
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
      console.log("this.excelData: ", this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }
      const rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error(
          "Only supports upload .xlsx, .xls, .csv suffix files"
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$set(this.excelData, "header", []);
        this.$set(this.excelData, "results", []);
        this.onSuccess(this.excelData);
        this.$message.error("请上传后缀为 .xlsx, .xls的文件");
        return;
      }
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel
      console.log("rawFile: ", rawFile);
      this.fileName = rawFile.name;
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          // const workbook = XLSX.read(data, {
          //   type: "array"
          // });
          // const firstSheetName = workbook.SheetNames[0];
          // const worksheet = workbook.Sheets[firstSheetName];
          // const header = this.getHeaderRow(worksheet);
          // const results = XLSX.utils.sheet_to_json(worksheet);
          // if (!results.length) {
          //   this.$message.warning("请上传有数据的文件");
          // }
          // this.generateData({ header, results });
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .oprate {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .downLink {
      color: #409eff;
      font-size: 14px;
      margin-left: 4px;
      align-self: flex-end;
    }
  }

  .upload-tip {
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
.file-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .file-name {
    margin-top: 16px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
}
</style>
